/* titillium-web-200 - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-200italic - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-200italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-300 - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-300italic - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-300italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-regular - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-regular.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-italic - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-600 - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-600italic - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-600italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-700 - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-700italic - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-700italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-900 - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/titilliumweb/v15/titillium-web-v15-latin-ext_latin-900.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
