/* You can add global styles to this file, and also import other style files */
@import '../../mypay4-fe-common/src/lib/styles/styles.scss';

@import 'variables';

.mat-divider {
  border-top-color: $primary-lighter !important;
}

// table, alternate row color
tr.mypay4-element-row-alternate-withdetail:nth-child(4n+1){
  background-color: #45c9d321;
}
tr.mypay4-element-row-alternate:nth-child(2n+1){
  background-color: #45c9d321;
}

//hide recaptcha badge (https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed)
.grecaptcha-badge { visibility: hidden; }

.mypay-search-form {
  //background-color: $primary-lightest;
  margin-bottom: 30px;
}

.mypay-search-expansion-panel {
  background-color: $primary-lightest !important;
  padding-top: 10px;

  mat-expansion-panel-header {
    height: auto !important;
  }

  .mypay-search-form {
    margin-bottom: 0;
  }
}

.mat-drawer-shown {
  visibility: hidden! important;
  }


 *:focus, a:focus, button:focus {
  outline: 2px solid #EB6600;
}

